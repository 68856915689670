import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Components
import Header from "../components/global/header";
import "../components/styles/menu.css";

const Container = styled.div`
  padding: 50px 30px;

  color: #fff;
  background-color: ${props => props.bgColor};

  @media (max-width: 767px) {
    padding: 20px;
  }

  & .text-container {
    max-width: 700px;

    & h1 {
      font-weight: 600;
      margin: 0;
    }

    & a.back-to-articles {
      color: #fff;

      margin: 0;

      font-size: 14px;
      line-height: 18px;
    }

    & .article-title {
      margin: 30px 0 0 0;
    }

    & a {
      color: #fff;
    }
  }

  @media (max-width: 600px) {
    & h1 {
      font-size: 20px;
    }
  }
`;

const ArticleContentContainer = styled.div`
  &.article-image-container {
    margin: 30px 0 60px 0;

    & p {
      font-size: 14px;
    }
  }
`;

const Article = ({ data }) => {
  const articleContent = data.prismicArticle.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <ArticleContentContainer
          key={`article_content_container_module_${index}`}
          className="article-text-container"
        >
          <div
            className="article-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </ArticleContentContainer>
      );
    }

    if (
      content.slice_type === "image" &&
      content.primary.image.fluid !== null
    ) {
      return (
        <ArticleContentContainer
          key={`article_content_container_module_${index}`}
          className="article-image-container"
        >
          <img
            src={content.primary.image.fluid.srcWebp}
            srcSet={content.primary.image.fluid.srcSetWebp}
            alt={content.primary.image.alt}
          />
          <div
            className="article-image-caption"
            dangerouslySetInnerHTML={{
              __html: content.primary.image_caption.html,
            }}
          />
        </ArticleContentContainer>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicArticle.data.meta_title.text} – Spark*`}
        meta={[
          {
            name: "description",
            content: `${data.prismicArticle.data.meta_text.text}`,
          },
          {
            name: "og:title",
            content: `${data.prismicArticle.data.meta_title.text}`,
          },
          {
            name: "og:description",
            content: `${data.prismicArticle.data.meta_text.text}`,
          },
          {
            name: "og:image",
            content: `${data.prismicArticle.data.featured_image.url}`,
          },
          {
            name: "og:image:secure_url",
            content: `${data.prismicArticle.data.featured_image.url}`,
          },
          {
            name: "og:image:alt",
            content: `${data.prismicArticle.data.meta_title.text}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "og:type",
            content: `website`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicArticle.data.meta_title.text}`,
          },
          {
            name: "twitter:description",
            content: `${data.prismicArticle.data.meta_text.text}`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: `${data.prismicArticle.data.featured_image.url}`,
          },
        ]}
      />

      <div>
        <Header />

        <Container bgColor={data.prismicArticles.data.color}>
          <div className="text-container">
            <Link to={`/#our-thoughts`} className="back-to-articles">
              ← Back To Articles
            </Link>

            <div
              className="article-title"
              dangerouslySetInnerHTML={{
                __html: data.prismicArticle.data.title.html,
              }}
            />

            <div>{articleContent}</div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Article;

export const ArticleQuery = graphql`
  query SingleArticle($uid: String!) {
    prismicArticles {
      data {
        color
      }
    }
    prismicArticle(uid: { eq: $uid }) {
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicArticleDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicArticleDataBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
              image_caption {
                html
              }
            }
          }
        }
        meta_title {
          text
        }
        meta_text {
          text
        }
        featured_image {
          url
        }
      }
    }
  }
`;
